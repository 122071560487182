<template>
  <div v-if="data">
    <block-title>品項: </block-title>
    <div class="mb-5" v-if="orderCardData['normal'] && orderCardData['normal'].length > 0">
      <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #006DE0; color: #fff;">常溫</div>
      <store-order-card
        v-for="(item, index) in orderCardData['normal']"
        :key="index"
        :index="index"
        v-model="orderCardData['normal'][index]"
        :configs="cardConfigs('normal', index)"
      ></store-order-card>
    </div>
    <div class="mb-5" v-if="orderCardData['refrigeration'] && orderCardData['refrigeration'].length > 0">
      <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #0353a7; color: #fff;">冷藏</div>
      <store-order-card
        v-for="(item, index) in orderCardData['refrigeration']"
        :key="index"
        :index="index"
        v-model="orderCardData['refrigeration'][index]"
        :configs="cardConfigs('refrigeration', index)"
      ></store-order-card>
    </div>
    <div class="mb-5" v-if="orderCardData['freezing'] && orderCardData['freezing'].length > 0">
      <div class="px-2 py-1 mb-2 font-weight-bold" style="background-color: #002a56; color: #fff;">冷凍</div>
      <store-order-card
        v-for="(item, index) in orderCardData['freezing']"
        :key="index"
        :index="index"
        v-model="orderCardData['freezing'][index]"
        :configs="cardConfigs('freezing', index)"
      ></store-order-card>
    </div>
    <div>
      <number-bar
        v-if="origin_total_amounts"
        title="商品總計"
        unit="元"
        reload
        @reload="handleResetTotal"
        v-model="origin_total_amounts"
        readonly
        name="amount"
        :type="'mine'"
        class="border-0 store-bg-color white--text rounded-l-0"
        :fixed="0"
        style="width: 100%; background-color: #000!important;"
      ></number-bar>
      <number-bar
        v-if="orderCardData['normal'] && orderCardData['normal'].length > 0"
        title="常溫運費"
        unit="元"
        v-model="delivery_fee"
        readonly
        name="delivery_fee"
        :type="'mine'"
        class="border-0 store-bg-color white--text rounded-r-0"
        :fixed="0"
        style="width: 100%; background-color: #006de0!important;"
      ></number-bar>
      <number-bar
        v-if="orderCardData['refrigeration'] && orderCardData['refrigeration'].length > 0"
        title="冷藏運費"
        unit="元"
        v-model="refrigeration_delivery_fee"
        readonly
        name="delivery_fee"
        :type="'mine'"
        class="border-0 store-bg-color white--text rounded-r-0"
        :fixed="0"
        style="width: 100%; background-color: #0353a7!important;"
      ></number-bar>
      <number-bar
        v-if="orderCardData['freezing'] && orderCardData['freezing'].length > 0"
        title="冷凍運費"
        unit="元"
        v-model="freezing_delivery_fee"
        readonly
        name="delivery_fee"
        :type="'mine'"
        class="border-0 store-bg-color white--text rounded-r-0"
        :fixed="0"
        style="width: 100%; background-color: #002a56!important;"
      ></number-bar>
      <number-bar
        v-if="amount"
        title="含運總計"
        unit="元"
        reload
        @reload="handleResetTotal"
        v-model="amount"
        readonly
        name="amount"
        :type="'mine'"
        class="border-0 store-bg-color white--text rounded-l-0"
        :fixed="0"
        style="width: 100%; background-color: #00162d!important;"
      ></number-bar>
  </div>

    <v-textarea
      v-show="comment && comment.length > 0"
      v-model="comment"
      label="備註"
      outlined
      name="comment"
      auto-grow
      readonly
    ></v-textarea>

    <v-textarea
      v-show="provider_comment && provider_comment.length > 0"
      v-model="provider_comment"
      label="供應商備註"
      outlined
      name="provider_comment"
      auto-grow
      readonly
    ></v-textarea>

    <input
      v-for="(item, index) in data"
      :key="item.item_id"
      hidden
      :name="`items[${index}][item_id]`"
      :value="item.item_id"
    />
  </div>
</template>

<script lang="babel" type="text/babel">
import orderItemMixins from "@/mixins/orderItem.js";
export default {
  mixins: [orderItemMixins],
  components: {
    "store-order-card": () =>
      import("@/modules/base/components/priceCard/order/storeOrderCard.vue"),
    "block-title": () => import("@/modules/base/components/blockTitle.vue"),
    "number-bar": () => import("@/modules/base/components/bar/numberBar.vue"),
  },
  data: () => ({
    data: null,
    orderCardData: {},
    comment: null,
    provider_comment: null,
  }),
  computed: {
    status() {
      if (!this.propsValue) return "";
      return this.propsValue.status;
    },
    editAble() {
      return ["created", "apply"].includes(this.status);
    },
    backOrder() {
      if (!this.propsValue) return null;
      return this.propsValue.backOrder;
    },
    backOrderStatus() {
      if (!this.backOrder) return null;
      return this.backOrder.backOrder_status;
    },
    // secondary 黑, primary 淺
    itemNumberBarTheme() {
      if (this.backOrder) {
        if (this.backOrderStatus == "REJECT") return "secondary";
        return "primary";
      }
      return "secondary";
    },
    itemNumberBarTitle() {
      return "總計";
    },
    itemAmountTitle() {
      return "小計";
    },
    usePropsAmount() {
      return true;
    },
  },
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.propsValue) return;
        this.data = this.$eagleLodash.cloneDeep({
          normal: this.propsValue.normal_items,
          refrigeration: this.propsValue.refrigeration_items,
          freezing: this.propsValue.freezing_items
        })
        this.comment = this.propsValue.comment;
        this.provider_comment = this.propsValue.provider_comment;
        this.propsAmount = this.propsValue.total_amounts;
        this.origin_total_amounts = this.propsValue.origin_total_amounts;
        this.delivery_fee = Number(this.propsValue.delivery_fee).toFixed(0);
        this.refrigeration_delivery_fee = Number(this.propsValue.refrigeration_delivery_fee).toFixed(0);
        this.freezing_delivery_fee = Number(this.propsValue.freezing_delivery_fee).toFixed(0);
      },
    },
    data: {
      deep: true,
      async handler() {
        for (let dataTemp in this.data) {
          this.orderCardData[dataTemp] = this.data[dataTemp].map((item) => {
            return {
              shipping_unit: item.shipping_unit, // 出貨單位
              calculate_unit: item.calculate_unit, // 計價單位
              per_shipping_count: item.per_shipping_count, //  ex: 規格:每籃5包
              shipping_count: Number(this.$helper.reverseShippingCount(
                item.shipping,
                item.shipping_unit
              )).toFixed(0), // 出貨數量
              origin_price: Number(item.origin_price).toFixed(0),
              price: this.editAble ? Number(this.getInitialPrice(item)).toFixed(0) : Number(item.price).toFixed(0), // 單價
              order_count: Number(this.$helper.reverseShippingCount(
                item.count,
                item.shipping_unit
              )).toFixed(0), // 訂購數量 / 數量
            };
          });
        }
        // 一開始要等子元件渲染完
        await this.$helper.delay(0.5);
        await this.getChildrenTotal();
      },
    },
    orderCardData: {
      deep: true,
      async handler() {
        await this.getChildrenTotal();
      },
    },
  },
  methods: {
    cardConfigs(temp, index) {
      const item = this.data[temp][index];
      let configs = [
        { label: item.product, colon: false, class: "border-b" },
        { id: "order_count", label: "訂購數量", col: 6 },
        {
          id: "origin_price",
          label: "訂購單價",
          col: 6,
          name: `items[${index}][origin_price]`,
          valueSuffix: "元",
        },
        { id: "shipping_count", label: "出貨數量", col: 6 },
        {
          id: "price",
          label: "出貨單價",
          col: 6,
          name: `items[${index}][price]`,
          valueSuffix: "元",
        },
        { label: "", colon: false, col: 6 },
      ];

      // 出貨數量小於訂購數字 -> 缺貨
      if (item.shipping < item.count) {
        configs = [
          { label: item.product, colon: false, class: "border-b", col: 6 },
          { label: "缺貨", col: 6, colon: false, class: "border-b red--text" },
          { id: "order_count", label: "訂購數量", col: 6 },
          {
            id: "origin_price",
            label: "訂購單價",
            col: 6,
            name: `items[${index}][origin_price]`,
            valueSuffix: "元",
          },
          { id: "shipping_count", label: "出貨數量", col: 6 },
          {
            id: "price",
            label: "出貨單價",
            col: 6,
            name: `items[${index}][price]`,
            valueSuffix: "元",
          },
          { label: "", colon: false, col: 6 },
        ];
      }

      if (this.status != "canceled") {
        configs.push({
          id: "total",
          label: "小計",
          type: "total",
          barType: "primary",
          valueSuffix: "元",
          col: 6,
        });
      }

      return configs;
    },
  },
};
</script>
